import React from 'react'
import { graphql } from 'gatsby'

import PageFactory from '@/components/PageFactory'

class FlowerBeautyAboutPage extends React.Component<any, any> {
  render() {
    const { page, header, footer } = this.props.data
    return <PageFactory page={page} footer={footer} header={header} />
  }
}

export default FlowerBeautyAboutPage

export const query = graphql`
  query FlowerBeautyAboutPage {
    page: datoCmsFlowerBeautyAboutPage {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }

      sections {
        __typename
        ...allSections
      }
    }

    header: datoCmsNavSection {
      ...NavSection
    }

    footer: datoCmsFooter {
      ...Footer
    }
  }
`
